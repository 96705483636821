import React from 'react'
import { useEffect, useState } from 'react';
import useWordle from '../hooks/useWordle'
import Grid from './Grid';
import HowModel from './HowModel';
import Keypad from './Keypad';
import Modal from './Modal';
import Timer from './Timer';

export default function Wordle({solution, hint}) {
    const {currentGuess,handleKeyUp, guesses , isCorrect, turn, usedKeys, setKeypadLetter}=useWordle(solution);
    const [showModal, setShowModal] = useState(false);
    const [firstTime, setFirstTime] = useState(false);
    const [time, setTime] = useState(300);
    const IS_PLAYED="isPlayed";

    useEffect(()=>{
        window.addEventListener('keyup',handleKeyUp)

        if (isCorrect) {
            setTimeout(()=>{setShowModal(true)},2000)
            window.removeEventListener('keyup',handleKeyUp);
        }

        if (turn>5) {
            setTimeout(()=>{setShowModal(true)},2000)
            window.removeEventListener('keyup',handleKeyUp);
        }

        return()=>window.removeEventListener('keyup',handleKeyUp);
    },[handleKeyUp,isCorrect,turn])

    const keypadPressed=((e)=>{
        if (!isCorrect) {
            setKeypadLetter(e.target.innerText.toLowerCase());
        }
        e.target.classList.add('clicked');
        setTimeout(() => {
            e.target.classList.remove('clicked');
        }, 100);
    });

    const reloadGame=(()=>{
        
        //window.location.reload(false);
    });

    useEffect(() => {
      if (!firstTime) {
        if (localStorage.getItem(IS_PLAYED)) {
          
        }
        else{
          localStorage.setItem(IS_PLAYED,true);
          setFirstTime(true);
        }
      }
      
    }, [])

    useEffect(()=>{
        if (time===0) {
            
            setTimeout(()=>{setShowModal(true)},2000)
            window.removeEventListener('keyup',handleKeyUp);
        }
    },[time])
    

  return (
    <div>
    <Timer hint={hint} time={time} setTime={setTime} isCorrect={isCorrect}/>
    
    {firstTime && <HowModel/>}
    <Grid currentGuess={currentGuess} guesses={guesses} turn={turn}/>
    <Keypad usedKeys={usedKeys} keypadPressed={keypadPressed}/>
    { showModal && <Modal isCorrect={isCorrect} turn={turn} solution={solution} time={time}/>}
    </div>
  )
}
