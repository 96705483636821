import React from 'react'
import {Link} from 'react-router-dom'

export default function Modal({isCorrect, turn, solution,time}) {
  return (
    <div className='modal2'>
        {isCorrect && (
            <div>
                <h1>You Win!</h1>
                <p className="solution">{solution}</p>
                <p>You found the solution in {turn} guesses :)</p>
                <div>
                <a href='/' className="btn btn-primary" data-toggle="tooltip" data-placement="bottom" title="Restart"><i className='fa fa-refresh'></i></a>
                <a href='/' className="btn btn-warning" data-toggle="tooltip" data-placement="bottom" title="Statistics"><i className='fa fa-bar-chart'></i></a>
                <Link to='/next' className='btn btn-success' data-toggle="tooltip" data-placement="bottom" title="Next"><i className="fa fa-chevron-right"></i></Link>
                </div></div>
        )}
        {time===0 && (
            <div>
                <h1>Out Of Time!</h1>
                <p className="solution">{solution}</p>
                <p>Better luck next time :(</p>
                <div>
                <a href='/' className="btn btn-primary" data-toggle="tooltip" data-placement="bottom" title="Restart"><i className='fa fa-refresh'></i></a>
                <a href='/' className="btn btn-warning" data-toggle="tooltip" data-placement="bottom" title="Statistics"><i className='fa fa-bar-chart'></i></a>
                <button className='btn btn-success' data-toggle="tooltip" data-placement="bottom" title="Next"><i className="fa fa-chevron-right"></i></button>
            </div></div>
        )}
        {time !== 0 && !isCorrect && (
            <div>
                <h1>Nevermind!</h1>
                <p className="solution">{solution}</p>
                <p>Better luck next time :(</p>
                <div>
                <a href='/' className="btn btn-primary" data-toggle="tooltip" data-placement="bottom" title="Restart"><i className='fa fa-refresh'></i></a>
                <a href='/' className="btn btn-warning" data-toggle="tooltip" data-placement="bottom" title="Statistics"><i className='fa fa-bar-chart'></i></a>
                <button className='btn btn-success' data-toggle="tooltip" data-placement="bottom" title="Next"><i className="fa fa-chevron-right"></i></button>
            </div></div>
        )}
        
    </div>
  )
}
