import HowToPlay from "./HowToPlay";

const HowModel = () => {
    return ( 
        <><div className="modal show" style={{"display":"block"}} id="exampleModalScrollable" tabIndex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              
              <button type="button" className="close" style={{"marginLeft":"auto"}} data-dismiss="modal" aria-label="Close" onClick={(e)=>{
                document.getElementById('exampleModalScrollable').className='modal show fadeOut';
                
                setTimeout(() => {
                  document.getElementById('exampleModalScrollable').style="display:none";
                }, 400);
              }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <HowToPlay/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"onClick={(e)=>{
                document.getElementById('exampleModalScrollable').className='modal show fadeOut';
                setTimeout(() => {
                  document.getElementById('exampleModalScrollable').style="display:none";
                }, 400);
              }}>Close</button>
            </div>
          </div>
        </div>
      </div></>
     );
}
 
export default HowModel;