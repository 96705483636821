import { useEffect, useState } from "react";

const useWordle = (solution) => {

    const [turn,setTurn]=useState(0);
    const [currentGuess,setCurrentGuess]=useState('');
    const [guesses,setGuesses]=useState([...Array(6)]);
    const [history,setHistory]=useState([]);
    const [isCorrect,setIsCorrect]=useState(false);
    const [usedKeys, setUsedKeys]=useState({}) //{a: 'green', b:'yellow', c: 'grey'}
    const [keypadLetter, setKeypadLetter] = useState('');

    // format a guess into an array of letter objects
    // e.g [{key:'a', color:'yellow'}]
    const formatGuess=()=>{
        let solutionArray=[...solution];
        let formatedGuess=[...currentGuess].map((l)=>{
            return{key:l,color:'grey'};
        })

        // find any green letters
        formatedGuess.forEach((l,i)=>{
            if (solutionArray[i]===l.key) {
                formatedGuess[i].color='green';
                solutionArray[i]=null;
            }
        })

        // find any green letters
        formatedGuess.forEach((l,i)=>{
            if (solutionArray.includes(l.key) && l.color!=='green') {
                formatedGuess[i].color='yellow';
                solutionArray[solutionArray.indexOf(l.key)]=null;
            }
        })

        return formatedGuess;
    }

    // add a new huess to the gueses state
    // update the isCorrect state if the guess is correct
    // add one to the turn state
    const addNewGuess=(formatedGuess)=>{
        if (currentGuess===solution) {
            setIsCorrect(true);
        }
        setGuesses((prevGuesses)=>{
            let newGuesses =[...prevGuesses];
            newGuesses[turn]=formatedGuess;
            return newGuesses;
        })

        setHistory((prevHistory)=>{
            return [...prevHistory,currentGuess];
        })

        setTurn((prevTurn)=>{
            return prevTurn+1;
        })
        setUsedKeys((PrevUsedKeys)=>{
            let newKeys={...PrevUsedKeys}

            formatedGuess.forEach((l)=>{
                const currentColor=newKeys[l.key]

                if (l.color==='green') {
                    newKeys[l.key]='green';
                    return;
                }
                if (l.color==='yellow' && currentColor!=='green') {
                    newKeys[l.key]='yellow';
                    return;
                }
                if (l.color==='grey' && currentColor!=='green' && currentColor!=='yellow') {
                    newKeys[l.key]='grey';
                    return;
                }
            })

            return newKeys;
        })
        setCurrentGuess('');
    }

    // handle key up event & track current guess
    // if user presses enter, add the new guess
    const handleKeyUp=({key})=>{
        if (key==='Enter') {
            //only add guess if turn is less than 5
            if (turn>5) {
                console.log('you used all your guesses');
                return;
            }
            //do not allow duplicate guess
            if (history.includes(currentGuess)) {
                console.log('you already tried that word');
                return;
            }
            //check word is 5 chars long
            if (currentGuess.length!=5) {
                console.log('word must be 5 chars long');
                return;
            }

            const formatted=formatGuess();
            addNewGuess(formatted)
        }
        if (key==='Backspace') {
            setCurrentGuess((prev)=>{
                return prev.slice(0,-1);
            });
            return
        }
        if (/^[A-Za-z]$/.test(key)) {
            if (currentGuess.length<5) {
                setCurrentGuess((prev)=>{
                    return prev+key;
                });
            }
        }
    }

    useEffect(()=>{
        if (keypadLetter==='enter') {
            //only add guess if turn is less than 5
            if (turn>5) {
                console.log('you used all your guesses');
                return;
            }
            //do not allow duplicate guess
            if (history.includes(currentGuess)) {
                console.log('you already tried that word');
                return;
            }
            //check word is 5 chars long
            if (currentGuess.length!=5) {
                console.log('word must be 5 chars long');
                return;
            }

            const formatted=formatGuess();
            addNewGuess(formatted)
        }
        if (keypadLetter==='back') {
            setCurrentGuess((prev)=>{
                return prev.slice(0,-1);
            });
            setKeypadLetter('');
            return
        }
        if (/^[A-Za-z]$/.test(keypadLetter)) {
            if (currentGuess.length<5) {
                setCurrentGuess((prev)=>{
                    return prev+keypadLetter;
                });
            }
        }
        setKeypadLetter('');
    },[keypadLetter])

    return {turn,currentGuess,guesses,isCorrect, usedKeys,handleKeyUp,setKeypadLetter}
}
 
export default useWordle;