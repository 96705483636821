import { useState } from "react";
import { useEffect } from "react";
import Wordle from './Wordle';

const StartWordle = () => {
    const [solution,setSolution]= useState(null);
  const [hint, setHint] = useState('');
  const solutions=[
    {"id": 1, "word": "ninja", "hint":"a fighter"},
    {"id": 2, "word": "spade", "hint":"a shape"},
    {"id": 3, "word": "pools", "hint":"a nothing"},
    {"id": 4, "word": "drive", "hint":"a way of mobility"},
    {"id": 5, "word": "relax", "hint":"i am tired"},
    {"id": 6, "word": "times", "hint":"arithmetic"},
    {"id": 7, "word": "train", "hint":"a means of transportation"},
    {"id": 8, "word": "cores", "hint":"the center of fruits"},
    {"id": 10, "word": "blame", "hint":"it wasn't me"},
    {"id": 11, "word": "banks", "hint":"a place to safe"},
    {"id": 12, "word": "phone", "hint":"a number"},
    {"id": 13, "word": "bling", "hint":"something fancy"},
    {"id": 14, "word": "coins", "hint":"it has 2 sides"},
    {"id": 15, "word": "hello" , "hint":"a greeting"},
    {"id":16,"word":"panda", "hint":"an animal, white and black"},
    {"id":17,"word":"apple", "hint":"a fruit"},
    {"id":18,"word":"refer", "hint":"telling others"},
    {"id":19,"word":"first", "hint":"a position"},
    {"id":20,"word":"story", "hint":"something to tell"},
    {"id":21,"word":"focus", "hint":"paying attention"},
    {"id":22,"word":"state", "hint":"a place"},
    {"id":23,"word":"space", "hint":"a place"},
    {"id":24,"word":"floss", "hint":"for teeth"},
    {"id":25,"word":"fresh", "hint":"something new"},
    {"id":26,"word":"tooth", "hint":"mouth"},
    {"id":27,"word":"clean", "hint":"wash"},
    {"id":28,"word":"teeth", "hint":"mouth"},
    {"id":29,"word":"pains", "hint":"feelings"},
    {"id":30,"word":"learn", "hint":"paying attention"},
    {"id":31,"word":"offer", "hint":"nothing"}
  ];

  useEffect(()=>{
    // fetch('data/solutions.json',{
    //   headers : { 
    //       'Content-Type': 'application/json',
    //       'Accept': 'application/json'
    //      }
    // })
    // .then(res => res.json())
    // .then(json => {
    //   // random int between 0 & 14
    //   const randomSolution = json[Math.floor(Math.random()*json.length)]
    //   setSolution(randomSolution.word)
    //   setHint(randomSolution.hint);
    // })
        //random int btw 0 & 14
        const randomSolution=solutions[Math.floor(Math.random()*solutions.length)];
        setSolution(randomSolution.word);
        setHint(randomSolution.hint);
      console.log(solution)
  },[])
    return ( 
        <>{solution && <Wordle solution={solution} hint={hint}/>}</>
     );
}
 
export default StartWordle;