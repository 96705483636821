import React, { Fragment } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'

export default function Keypad({usedKeys,keypadPressed}) {
    const [letters,setLetters]=useState(null);
    const Kletters=[
        {"key": "q"},
        {"key": "w"},
        {"key": "e"},
        {"key": "r"},
        {"key": "t"},
        {"key": "y"},
        {"key": "u"},
        {"key": "i"},
        {"key": "o"},
        {"key": "p"},
        {"key": "a"},
        {"key": "s"},
        {"key": "d"},
        {"key": "f"},
        {"key": "g"},
        {"key": "h"},
        {"key": "j"},
        {"key": "k"},
        {"key": "l"},
        {"key":"Enter"},
        {"key": "z"},
        {"key": "x"},
        {"key": "c"},
        {"key": "v"},
        {"key": "b"},
        {"key": "n"},
        {"key": "m"},
        {"key":"Back"}
      ];
    useEffect(()=>{
       
            setLetters(Kletters);
       
    },[])

    
  return (
    <div className='keypad'>
        {letters && letters.map((l,i)=>{
            const color=usedKeys[l.key];
            if (i===10||i===19) {
                return(
                    <Fragment key={l.key}><br /><div  className={`noselect ${color} ${l.key==='Back'||l.key==='Enter'?'funK':''}`} onClick={keypadPressed}>{l.key}</div></Fragment>
                )
            }
            return(
                <div key={l.key} className={`noselect ${color} ${l.key==='Back'||l.key==='Enter'?'funK':''}`} onClick={keypadPressed}>{l.key}</div>
            )
        })}
    </div>
  )
}
