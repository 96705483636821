import { useEffect, useState } from "react";

import React from 'react'

export default function Timer({hint, time,setTime,isCorrect}) {
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    let s=time;

    const startTimer= (()=> {
        if(s%60 <= 9){
            setSeconds("0" + s%60);
        }
        else{
            setSeconds(s%60);
        }
        if(Math.floor(s/60) <= 9){
            setMinutes("0" + Math.floor(s/60));
        }
        else{
            setMinutes(Math.floor(s/60));
        }
        if (s>0) {
            s--;
        }
            setTime(s);
        
      });

      useEffect(()=>{
        
            setTimeout(() => {
                if (!isCorrect) {
                    startTimer();
                }
                console.log(isCorrect);
              }, 980);
        
      },[time,isCorrect]);

    return ( 
        <p><span style={{"margin":"5px"}}>Guess what am thinkng... Hint: {hint}</span><span className={`timer ${!isCorrect?'ani':''}`}>{minutes}:{seconds}</span></p>
     );
}
