const HowToPlay = () => {
    return ( 
        <div className="text-start container-xxl">
            <h1 className="text-bg-success text-capitalize text-center">how to play</h1>
            <p className="mt-5">Guess the <strong>WORDLE</strong> in 6 tries.</p>
            <p>Each guess must be a valid 5-letter word. Hit the enter button to submit.</p>
            <p>After each guess, the color of the tiles will change to show how close your guess was to the word.</p>
            <hr /><p><strong>Examples</strong></p>
            <div className='row' style={{"justifyContent":"start"}}>
        <div className='green1'>w</div>
        <div className=''>e</div>
        <div className=''>a</div>
        <div className=''>r</div>
        <div className=''>y</div>
    </div>
    <p>The letter <strong>W</strong> is in the word and in the correct spot.</p>
    <div className='row' style={{"justifyContent":"start"}}>
        <div className=''>p</div>
        <div className='yellow1'>i</div>
        <div className=''>l</div>
        <div className=''>l</div>
        <div className=''>s</div>
    </div>
    <p>The letter <strong>I</strong> is in the word but in the wrong spot.</p>
    <div className='row' style={{"justifyContent":"start"}}>
        <div className=''>v</div>
        <div className=''>a</div>
        <div className=''>g</div>
        <div className='grey1'>u</div>
        <div className=''>e</div>
    </div>
    <p>The letter <strong>U</strong> is not in the word in any spot.</p>
        </div>
     );
}
 
export default HowToPlay;